import React from 'react';
import Modal from "react-modal";
import './ConsentConfirmationPopup.css'

export default function ConsentConfirmationPopup(props) {
    
    const leftButtonHandler = () => {   
        props.leftButtonClick();
    } 

    const rightButtonHandler = () => {   
        props.rightButtonClick();
    } 

    const closeButtonHandler = () => {   
        props.closeModalButtonClick();
    } 

    return (
        <div>
            <Modal
                isOpen={props.isModalOpen}
                onAfterOpen={props.onAfterOpen}
                contentLabel={props.contentLabel}
                className={props.modalClassName}
                overlayClassName={props.overlayClassName}
                closeTimeoutMS={500}
                >
                <div className={props.showCloseModalButton ? undefined : 'hidden'}>
                    <button type="button" className={`modal__close ${props.closeModalButtonClassName}`} data-dismiss="modal" onClick={closeButtonHandler} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>       
                <br></br>
                <div className={props.bodyClassName}>
                    {props.content}
                </div>
                <hr className={props.hrClassName}/>
                <div className="text-center"> 
                    <button type="button" className={`btn button ${props.leftButtonTextClassName}`} onClick={leftButtonHandler}>{props.leftButtonText}</button>
                    <button type="button" className={`btn button ${props.rightButtonTextClassName}`} onClick={rightButtonHandler}>{props.rightButtonText}</button>
                </div>
            </Modal>
        </div>
    )
}
