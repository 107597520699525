import React, { useCallback, useState, useEffect} from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GenericError from '../StaticRedirects/GenericError';
import './AuthPage.css';


function AuthPage (props){ 
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState({status:null});
  const [isLoaded, setIsLoaded] = useState(false); 
  const [inputCode, setInputCode] = useState('');
  const [responseStatus, setResponseStatus] = useState(0); 
  const {id, phone, lenderId, language} = props;
  const [t] = useTranslation();
  
  const generateCodeHandler = (e) => {
    e.preventDefault();
    generateCode(true);
  };

  const generateCode = useCallback((skipSmsLifeValidation = false) => {
    fetch(process.env.REACT_APP_API + `SendCode`, { 
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        skipSmsLifeValidation: skipSmsLifeValidation,
        requestId: id
      })
    })
    .then(data => data.json())
    .then(data => {
      setIsLoaded(true);
    },
    (error) => {
      setError(error);
      setIsLoaded(true);
    })  
  }, [id]);

  const verfiyCode = () => {
    fetch(process.env.REACT_APP_API + `ValidateCode?id=${id}&code=${inputCode}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    })   
    .then((response) => { 
      // Note: once the responseStatus changes 
      // the validateResponse function will be triggered
      window.sessionStorage.setItem("jwt", response.headers.get('jwt'));
      setResponseStatus(response.status);  
      return response.json();
    },
    (error) => {
      setIsLoaded(true);
      setError(error);
    })  
  };

  const validateResponse = useCallback(() => {
    switch(responseStatus) {
      case 403:
        setValidationError({status: t("ExpiredVerificationCode")});
        return;
      case 401:
        setValidationError({status: t("InvalidVerificationCode")});
        return;
      case 429:
        setValidationError({status: t("LoginAttemptsExceeded")});
      return;
      case 200:
        setValidationError({status: null});
        var dataset = {requestId: id, validated:true};
        props.authPageAppValues(dataset);
        let sessionStorage = window.sessionStorage;
        sessionStorage.setItem('needToLogOut', true);
        return;
      default:
        return;
    }
  },[props,responseStatus, id, t]);

  const handleChange = (event) => {
    setInputCode(event.target.value);
  };

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      verfiyCode();
    }
  }

  useEffect(() => {
    validateResponse(responseStatus);
  }, [responseStatus, validateResponse]);

  useEffect(() => {
    generateCode();
  }, [generateCode]);

  const addDefaultLogoSrc = (event) => {
    event.target.src = process.env.PUBLIC_URL + './dealertrack-logo-vector.svg';
    event.target.style.margin = "0rem";
    event.target.style.width = "14rem";
  };

  if (error) {
    return <GenericError t={t}/> 
  } else if (!isLoaded) {
    return <div className="circularprocess"> <CircularProgress /></div>; 
  } else if ({responseStatus}) {  
  return(
      <div id="wrapper">
        <div id="dialog"> 
          <div className="row">        
            <img id="logo" onError={addDefaultLogoSrc} className="img-responsive" src={`${process.env.REACT_APP_IMAGES}${lenderId.toUpperCase()}/${lenderId.toUpperCase()}logo_update${language.toUpperCase().replace("-CA", "")}.png`} alt="logo"/>
          </div>
          <div className="row">         
            <div id="header">
              {t("SignerLogin")}
            </div>
          </div>
          <div className="row"> 
            <div id="body">
              {t("EnterSMSCode")} (XXX-XXX-{phone}) {t("ToSecureYourTransaction")}
            </div>          
          </div>
          <div id="form" className="row">         
              <input type="text" maxLength="6" size="6" min="0" max="9" pattern="[0-9]{1}" value={inputCode} onKeyPress={handleKeyPress} autoFocus onChange={handleChange}/>                       
          </div>
          <div className="row"> 
            <div id="sendsms">
              <a href="/#" onClick={generateCodeHandler}>{t("NeedSMSCode")}</a><br /> 
            </div>
          </div>
          <div className="row">
            <div id='validationError'>{validationError.status}</div>
          </div>
          <div className="row">
            <div id="button">
              <button className="btn btn-primary" disabled={inputCode.length !== 6} onClick={verfiyCode}>{t("Login")}</button>    
            </div>
          </div>       
        </div>
      </div>        
    );
  }
  else { 
    return <GenericError t={t}/> 
  };
}

export default (AuthPage);