import React from 'react'
import FadeIn from 'react-fade-in';
import { Container, Row, Col } from 'react-bootstrap'; 
import './ConsentConfirmedThankYou.css';

export default function ConsentConfirmedThankYou(props) {

    const {lenderId, language} = props;

    const addDefaultLogoSrc = (event) => {
        event.target.src = process.env.PUBLIC_URL + './dealertrack-logo-vector.svg';
        event.target.style.margin = "2rem";
        event.target.style.width = "22rem";
    };

    return (
        <div> 
            <FadeIn transitionDuration="2000">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={2} lg={2}>
                            <img id="consent-confirmation-logo" onError={addDefaultLogoSrc} className="consent-confirmation-logo" src={`${process.env.REACT_APP_IMAGES}${lenderId.toUpperCase()}/${lenderId.toUpperCase()}logo_update${language.toUpperCase().replace("-CA", "")}.png`} alt="logo"/>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}> 
                            <div className="consent-confirmation-thank-you__wrapper">
                                <div className="consent-confirmation-thank-you__header-text">
                                    <h2 className={props.itemsClassColor}><b>{props.headerText}</b></h2>
                                </div>
                                <div className="consent-confirmation-thank-you__body-text">
                                    {props.bodyText}
                                </div>
                            </div>                    
                        </Col>                          
                    </Row>           
                </Container>
            </FadeIn>
      </div>
    );
}
